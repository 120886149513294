import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon} from '@heroicons/react/solid'

const SearchPage = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate()

  const handleSearch = (e) => {
		e.preventDefault()

		if (searchQuery) {
			navigate(`/ip/${searchQuery}`, { replace: false})
		}
  };
  return (
    <div className="w-full md:p-16 flex items-center mt-5">
      <div className="md:w-1/2 m-auto">
				<h1 className=" text-[2.5rem] md:text-[3.5rem] font-semibold text-center">Loginsoft</h1>
				<h2 className=" text-[2.5rem] md:text-[3.5rem] font-black text-center text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-yellow-400">Cytellite</h2>
				<h3 className="text-[1rem] md:text-[2rem] text-center">For Insights & Enrichment of IP's</h3>
				<div className="flex justify-between h-12 md:h-14 border border-neutral-400 items-center rounded-lg p-3 my-10">
					<input type="text" placeholder="Search IPs" className="w-full border-none outline-none" onChange={(e) => setSearchQuery(e.target.value)}/>
					<button className="flex justify-between items-center bg-orange-500 text-white font-semibold text-sm tracking-wide px-3 py-2 rounded gap-3" onClick={handleSearch}>
						<SearchIcon className="w-5"/>
						<span>SEARCH</span>
					</button>
				</div>
			</div>
			{/* <div className="w-1/2 flex gap-3 ">
				<div className=" flex-col w-1/2 space-y-5">
					<div className="text-white bg-neutral-900 px-8 py-6 rounded-lg">
						<h1 className=" text-2xl tracking-wide font-medium">Top Malwares</h1>
						<h2 className=" text-5xl font-bold text-yellow-500 my-5">0</h2>
						{
							DUMMY_MALWARES.map((item, index) => {
								return (
									<div key={index} className="flex justify-between">
										<p className="border-solid border-b-[1.5px] leading-4 border-white mb-2">{item.name}</p>
										<p>{item.value}</p>
									</div>
								)
							})
						}
					</div>
					<div className="border border-neutral-200 shadow-sm px-8 py-6 rounded-lg">
						<h1 className=" text-2xl tracking-wide mb-5 font-medium">Exploit in Wild</h1>
						{
							DUMMY_EXPLOIT_IN_WILD.map((item, index) => {
								return (
									<div key={index} className="flex justify-between">
										<p className="border-solid border-b-[1.5px] leading-4 border-black mb-2">{item.cve}</p>
									</div>
								)
							})
						}
					</div>
				</div>
				<div className=" flex-col w-1/2 space-y-5">
					<div className=" px-8 py-6 rounded-lg border border-neutral-200">
						<h1 className=" text-2xl tracking-wide font-medium mb-6">Top Threat Score</h1>
						{
							DUMMY_TOP_THREAT_SCORE.map((item, index) => {
								return (
									<div key={index} className="flex justify-between">
										<p className="border-solid border-b-[1.5px] leading-4 border-black mb-2">{item.cve}</p>
									</div>
								)
							})
						}
					</div>
					<div className="text-white bg-neutral-900 px-8 py-6 rounded-lg">
						<h1 className=" text-2xl tracking-wide mb-5 font-medium">Top TTP</h1>
						{
							DUMMY_TOP_TTP.map((item, index) => {
								return (
									<div key={index} className="flex justify-between">
										<p className="border-solid border-b-[1.5px] leading-4 border-white mb-2">{item.ttp}</p>
									</div>
								)
							})
						}
					</div>
				</div>
			</div> */}
    </div>
  );
};

export default SearchPage;
