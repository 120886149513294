import { useState } from "react";
import { MenuIcon, XIcon, PhoneOutgoingIcon } from "@heroicons/react/solid";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenu = () => setShowMenu(!showMenu);
  return (
    <div className="h-14 text-white bg-neutral-800 z-10 ">
      <div className="flex h-14 justify-center items-center">
        <div className="w-full flex justify-between items-center">
          <img
            src="/Loginsoft-logo.png"
            alt="loginsoft-logo"
            className="mx-6"
          />
          <ul className="hidden md:flex items-center text-sm justify-between gap-16 mr-5">
            <li className="font-medium tracking-wide">
              <a href="/">Home</a>
            </li>
            <li className="font-medium tracking-wide">
              <a href="https://www.loginsoft.com/about/">About Us</a>
            </li>
            <a href="https://www.loginsoft.com/contact/">
              <li className="font-medium tracking-wide flex gap-3 items-center border border-white px-3 py-2 rounded-md hover:bg-white hover:border hover:border-black hover:text-black">
                <PhoneOutgoingIcon className="w-5" />
                Contact Us
              </li>
            </a>
          </ul>
          <div className="block md:hidden mr-5" onClick={handleMenu}>
            {!showMenu ? (
              <MenuIcon className="w-5" />
            ) : (
              <XIcon className="w-5" />
            )}
          </div>
        </div>
      </div>
      <div className="mr-5">
        <ul
          className={
            !showMenu ? "hidden" : "absolute w-full  bg-neutral-800 py-5"
          }
        >
          <li className="px-10 py-3 font-medium tracking-wide mx-3">
            <a href="/">Home</a>
          </li>
          <li className="px-10 py-3 font-medium tracking-wide mx-3">
            <a href="https://www.loginsoft.com/about/">About Us</a>
          </li>
          <a href="https://www.loginsoft.com/contact/">
            <li className="w-3/2 px-10 py-3 font-medium tracking-wide flex gap-3 items-center border border-white rounded-md hover:bg-white hover:border hover:border-black hover:text-black mx-3">
              <PhoneOutgoingIcon className="w-5" />
              Contact Us
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Header;
