import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import Header from "./components/Header";

const Layout = () => {
    const location = useLocation()

    return (
        <div className="w-full min-h-screen antialiased  font-mono">
            <Header />
            { location.pathname === '/' ? <SearchPage /> : <Outlet />}
        </div>
    )
}

export default Layout