import { Cross2Icon } from "@radix-ui/react-icons";

function Chip({ onDeleteHandler, id, text, showDelete = true }) {
  return (
    <span className="flex flex-row  items-center justify-center rounded-md bg-blue-50 px-1 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
      <span className="pr-1 font-bold">{text}</span>
      {showDelete && (
        <button
          onClick={() => onDeleteHandler(id)}
          className="text-gray-700 p-1 text-center h-[25px] w-[25px]"
        >
          <Cross2Icon />
        </button>
      )}
    </span>
  );
}

export default Chip;