import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    // const accessToken = localStorage.getItem("accessToken");
    // if (accessToken) {
    //   config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    //     "accessToken"
    //   )}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
