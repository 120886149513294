import { createBrowserRouter } from "react-router-dom";
import IpInfo from "./pages/IpInfo.jsx";
import Layout from "./Layout";

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/ip/:ip',
                element: <IpInfo />
            }
        ]
    },
])