import { useEffect } from "react";
import Chip from "../components/Chip";
import { useParams } from "react-router-dom";
import Axios from "../helpers/axios";
import { API_ENDPOINT } from "../config";
import { useState } from "react";
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import DialogDemo from "../components/Dialogue";

const IpInfo = () => {
  const { ip } = useParams();
  const [ipInfo, setIpInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (ip) {
      setLoading(true);
      Axios.get(`${API_ENDPOINT}/ips/${ip}`)
        .then((res) => {
          setLoading(false);
          setIpInfo(res.data.payload);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          console.log(err.message);
        });
    }
  }, [ip]);

  const lastSeenObject = ipInfo?.ip_last_seen?.toString()?.split("T");
  return (
    <div className="w-full h-full md:px-10 ">
      {!loading && (
        <>
          {open && (
            <DialogDemo open={open} setOpen={setOpen} data={ipInfo?.ports} />
          )}
          {ipInfo && (
            <div className="p-8 md:p-10 md:flex">
              <div className="md:w-2/3 md:border-solid md:border-r-[1px] md:border-neutral-600 md:pr-10">
                <div className="flex items-center gap-4 pb-2 border-solid border-b-[1px] border-neutral-300">
                  <h1 className="text-4xl md:text-5xl text-indigo-600">
                    {ipInfo?.ip}
                  </h1>
                  <ClipboardCopyIcon
                    className="w-4 md:w-5"
                    onClick={() => {
                      navigator.clipboard.writeText(ipInfo?.ip);
                    }}
                  />
                </div>
                <div className="block md:hidden">
                  <div className="my-5 border-solid border-b-[1px] border-neutral-300 pb-5">
                    <p className="font-semibold text-xl tracking-wider mb-2">
                      CLASSIFICATION
                    </p>
                    {ipInfo?.ip_classification !== "" && (
                      <span className="text-white  font-semibold py-1.5 px-3 text-sm bg-rose-500 rounded-md">
                        {ipInfo?.ip_classification?.toString()?.toUpperCase()}
                      </span>
                    )}
                    {ipInfo?.ip_classification === "" && (
                      <span>No Classification</span>
                    )}
                  </div>
                  <div className="flex-col justify-center items-center border-solid border-b-[1px] border-neutral-300 pb-2">
                    <label
                      htmlFor="tags"
                      className="font-semibold text-xl tracking-wider"
                    >
                      TAGS
                    </label>
                    <div className="flex flex-row flex-wrap gap-2 my-1">
                      {ipInfo?.tags?.length > 0
                        ? ipInfo.tags.map((tag, index) => (
                            <Chip
                              id={index}
                              text={tag}
                              key={index}
                              showDelete={false}
                            />
                          ))
                        : "No Tag"}
                    </div>
                  </div>
                </div>
                <div className="md:flex md:justify-start border-solid border-b-[1px] border-neutral-300 md:gap-32 md:my-5">
                  <div className="my-2">
                    <label
                      htmlFor="as_org"
                      className="text-neutral-600 text-sm tracking-wider"
                    >
                      ORGANIZATION
                    </label>
                    <h1 id="as_org" className="font-semibold tracking-wider">
                      {ipInfo?.who_is?.as_org}
                    </h1>
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="asn"
                      className="text-neutral-600 text-sm tracking-wider"
                    >
                      ASN NUMBER
                    </label>
                    <h1 id="asn" className="font-semibold tracking-wider">
                      {ipInfo?.who_is?.asn}
                    </h1>
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="lastseen"
                      className="text-neutral-600 text-sm tracking-wider"
                    >
                      IP LAST SEEN
                    </label>
                    <h1 id="lastseen" className="font-semibold tracking-wider">
                      {lastSeenObject?.[0]}
                    </h1>
                  </div>
                </div>

                {/* <div className="flex justify-start border-solid border-b-[1px] border-neutral-300 mb-3 gap-64 my-5 pb-2">
              <div>
                <label
                  htmlFor="lastseen"
                  className="text-neutral-600 text-sm tracking-wider"
                >
                  IP LAST SEEN
                </label>
                <h1 id="lastseen" className="font-semibold tracking-wider">
                  {lastSeenObject?.[0]}
                </h1>
              </div>
            </div> */}
                <div className="md:flex gap-5 mt-3 md:mt-0">
                  <div className="md:w-1/2 border border-neutral-300 rounded-lg p-4">
                    <h1 className="font-semibold tracking-wide mb-3">
                      Location Details
                    </h1>
                    <div className="flex gap-16">
                      <div>
                        <p className="text-sm text-neutral-600 mb-2">
                          Region Name
                        </p>
                        <p className="text-sm text-neutral-600 mb-2">Country</p>
                        <p className="text-sm text-neutral-600 mb-2">City</p>
                        <p className="text-sm text-neutral-600 mb-2">
                          Location
                        </p>
                      </div>
                      <div>
                        <p className=" text-black font-semibold mb-1">
                          {ipInfo?.who_is?.region_name}
                        </p>
                        <p className=" text-black font-semibold mb-1">
                          {ipInfo?.who_is?.country_name}
                        </p>
                        <p className=" text-black font-semibold mb-1">
                          {ipInfo?.who_is?.city_name}
                        </p>
                        <p className=" text-black font-semibold mb-1">
                          Lat - {ipInfo?.who_is?.location?.lat}
                        </p>
                        <p className=" text-black font-semibold mb-1">
                          Lon - {ipInfo?.who_is?.location?.lon}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/2 bg-neutral-900 text-white rounded-lg p-4 mt-3 md:mt-0">
                    <h1 className="  font-semibold tracking-wide mb-3">
                      Ports Scanned
                    </h1>
                    <div className="flex gap-32">
                      <div>
                        <h1 className=" text-sm font-semibold text-neutral-500 mb-2">
                          Port
                        </h1>
                        {ipInfo?.ports?.length === 0 && (
                          <p className=" text-sm font-semibold mb-2">
                            No Port scanned
                          </p>
                        )}
                        {ipInfo?.ports?.length > 0 &&
                          ipInfo?.ports?.length <= 3 && (
                            <>
                              {ipInfo?.ports?.map((port, index) => {
                                return (
                                  <p
                                    key={index}
                                    className=" text-sm font-semibold mb-2"
                                  >
                                    {port}
                                  </p>
                                );
                              })}
                            </>
                          )}
                        {ipInfo?.ports?.length > 0 &&
                          ipInfo?.ports?.length >= 3 && (
                            <>
                              {ipInfo?.ports?.map((port, index) => {
                                if (index < 3) {
                                  return (
                                    <p
                                      key={index}
                                      className=" text-sm font-semibold mb-2"
                                    >
                                      {port}
                                    </p>
                                  );
                                } else if (index === 3) {
                                  return (
                                    <div className="flex items-center gap-2">
                                      <p>Show more</p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5 text-orange-700 cursor-pointer"
                                        onClick={() => setOpen(true)}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                        />
                                      </svg>
                                    </div>
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                            </>
                          )}
                      </div>
                      {/* <div>
                    <h1 className=" text-sm font-semibold text-neutral-500 mb-2">
                      Protocol
                    </h1>
                    <p className=" text-sm font-semibold mb-2 text-center">-</p>
                    <p className=" text-sm font-semibold mb-2 text-center">-</p>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="w-full border border-neutral-300 rounded-lg p-4 my-5">
                  <h1 className="font-semibold tracking-wide mb-3">
                    Payload Printable
                  </h1>
                  <div className="flex flex-col gap-2">
                    {ipInfo?.payload_printable?.payload_printable.map(
                      (payload) => {
                        return (
                          <p className="text-sm text-neutral-600 mb-2">
                            {payload.trim()}
                          </p>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="w-full bg-neutral-900 text-white rounded-lg p-4 my-5">
                  <h1 className="font-semibold tracking-wide mb-3">
                    User Agents
                  </h1>
                  <div className="flex flex-col gap-2">
                    {ipInfo?.user_agents?.user_agents.map((payload) => {
                      return (
                        <p className="text-sm text-neutral-300 mb-2">
                          {payload.trim()}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full border border-neutral-300 rounded-lg p-4 my-5">
                  <h1 className="font-semibold tracking-wide mb-3">
                    Http URL
                  </h1>
                  <div className="flex flex-col gap-2">
                    {ipInfo?.http_urls?.http_urls.map(
                      (payload) => {
                        return (
                          <p className="text-sm text-neutral-600 mb-2">
                            {payload.trim()}
                          </p>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="w-full bg-neutral-900 text-white rounded-lg p-4 my-5">
                  <h1 className="font-semibold tracking-wide mb-3">
                    OS Info
                  </h1>
                  <div className="flex flex-col gap-2">
                    {ipInfo?.os_info?.os_info.map((payload) => {
                      return (
                        <p className="text-sm text-neutral-300 mb-2">
                          {payload.trim()}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full border border-neutral-300 rounded-lg p-4 my-5">
                  <h1 className="font-semibold tracking-wide mb-3">
                    JA3
                  </h1>
                  <div className="flex flex-col gap-2">
                    {ipInfo?.ja3_info?.ja3_info.map(
                      (payload) => {
                        return (
                          <p className="text-sm text-neutral-600 mb-2">
                            {payload.trim()}
                          </p>
                        );
                      }
                    )}
                  </div>
                </div>
                {/* <div>
                  <h1 className="font-semibold tracking-wide mb-3">
                    Organization Info
                  </h1>
                  <p className="text-neutral-600 text-sm tracking-wide">
                    Organization information will be provided soon.
                  </p>
                </div> */}
              </div>
              <div className="hidden md:block w-1/3 pl-10  ">
                <div className="my-5 border-solid border-b-[1px] border-neutral-800 pb-5">
                  <p className="font-semibold text-xl tracking-wider mb-2">
                    CLASSIFICATION
                  </p>
                  {ipInfo?.ip_classification !== "" && (
                    <span className="text-white  font-semibold py-1.5 px-3 text-sm bg-rose-500 rounded-md">
                      {ipInfo?.ip_classification?.toString()?.toUpperCase()}
                    </span>
                  )}
                  {ipInfo?.ip_classification === "" && (
                    <span>No Classification</span>
                  )}
                </div>
                <div className="flex-col justify-center items-center">
                  <label
                    htmlFor="tags"
                    className="font-semibold text-xl tracking-wider"
                  >
                    TAGS
                  </label>
                  <div className="flex flex-row flex-wrap gap-2 my-1">
                    {ipInfo?.tags?.length > 0
                      ? ipInfo.tags.map((tag, index) => (
                          <Chip
                            id={index}
                            text={tag}
                            key={index}
                            showDelete={false}
                          />
                        ))
                      : "No Tag"}
                  </div>
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className="flex justify-center items-center h-[300px]">
              <div className=" text-xl text-red-600 tracking-wider font-medium p-10 border border-red-500 bg-red-100 rounded-lg">
                IP address doesn't exist.
              </div>
            </div>
          )}
        </>
      )}
      {loading && (
        <div className="text-center h-full">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default IpInfo;
